
import { defineComponent, watch, reactive, onMounted } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import mAxiosApi from "@/api";
import { ElMessageBox } from 'element-plus'


export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const state = reactive({
      initialContact: "",
      societe: "test jle",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
      myList: [] as any,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;

      state.societe = store.getters.currentUser.societeCodeArk ? store.getters.currentUser.societeCodeArk : "";

      state.initialContact =
        (state.contact.uti_c_prenom ? state.contact.uti_c_prenom.charAt(0) : "") +
        (state.contact.uti_c_nom ? state.contact.uti_c_nom.charAt(0) : "");
    }

    onMounted(async () => {

      let myListLiv = {} as any;
      myListLiv = await getAxios("/getMyRole");
      state.myList = myListLiv;

    });

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const onidle= () => {
      signOut();
    };

    const adminApp = () => {
      router.push({ name: "admin" });
    }

    const signOut = () => {
      const refCodeArk = store.getters.currentUser.refCodeArk;

      mAxiosApi.post("/logout");
      store
        .dispatch(Actions.LOGOUT)
        .then(() => {   
          router.push({ name: "sign-in", params: { 'codeArk': refCodeArk } }).then(() => {   
            ElMessageBox.alert("En raison d’une période d’inactivité, vous avez été automatiquement déconnecté.", 'Déconnexion automatique', {
              confirmButtonText: 'OK'
            })
          });
        } );
    };

    return { state, reloadRouter,adminApp,onidle };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
