<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="
        d-flex
        flex-column flex-md-row
        align-items-center
        justify-content-between
      "
      :class="{
        'container-fluid': footerWidthFluid,
        'container-xxl': !footerWidthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">2023 | Propriété exclusive de </span>
        <a
          href="https://www.stillnetwork.com/"
          target="_blank"
          class="text-gray-800 text-hover-primary"
          >Stillnetwork</a
        >
      </div>
      <!--end::Copyright-->

      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a
            href="https://www.stillnetwork.com/a-propos/"
            target="_blank"
            class="menu-link px-2"
            >A propos</a
          >
        </li>

        <li class="menu-item">
          <a
            href="https://www.stillnetwork.com/notre-philosophie/"
            target="_blank"
            class="menu-link px-2"
            >Notre philosophie</a
          >
        </li>

        <li class="menu-item">
          <a
            href="https://www.stillnetwork.com/contact/"
            target="_blank"
            class="menu-link px-2"
            >Contactez-nous !</a
          >
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
    };
  },
});
</script>
